$RadioSizeOutside: 14px !default;
$RadioSizeInside: ($RadioSizeOutside - 6px) !default;
$RadioInsidePos: (($RadioSizeOutside - $RadioSizeInside) * 0.5) !default;

.radio-options {
	display: block;
}

.radio-item {
	&.inlblk {
		display: inline-block;
	}
	& + .radio-item.inlblk {
		margin-left: 1rem;
	}
	label {
		cursor: pointer;
		input[type="radio"] {
			display: none;
		}
		.radio {
			display: inline-block;
			position: relative;
			line-height: 1;
			&:before {
				background-color: $white;
				border: 1px solid $gray;
				border-radius: 50%;
				content: "";
				display: inline-block;
				height: $RadioSizeOutside;
				vertical-align: middle;
				width: $RadioSizeOutside;
			}
		}
		input[type="radio"]:checked + .radio {
			&:after {
				background: $forestgreen;
				border-radius: 50%;
				bottom: $RadioInsidePos;
				content: "";
				left: $RadioInsidePos;
				height: $RadioSizeInside;
				position: absolute;
				width: $RadioSizeInside;
			}
		}
		.radio-label {
			display: inline-block;
			vertical-align: middle;
		}
	}
}
