// local mixins
@mixin generate-columns($cols: 12, $prefix: "", $value: 0) {
	$ColNumber: if(is-number($cols), $cols, 12);
	@while $ColNumber > 0 {
		.col-#{$prefix}span#{$ColNumber} {
			@include col-width(if($value != 0 and is-number($value), $value, $ColNumber));
		}
		$ColNumber: $ColNumber - 1;
	}
}

.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	.no-flexbox & {
		display: table;
		table-layout: fixed;
	}
}

@include generate-columns(11, "", 12);

.col-full {
	@include col-width(12);
}

// Permanent columns
@include generate-columns(11, "pm");

@include tablet {
	@include generate-columns(11);
}
