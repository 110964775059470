.hidden {
	display: none;
}

.inlblk {
	display: inline-block;
}

.page-title {
	font-size: 1.3rem;
	font-weight: bold;
}
