$NunitoFontPath: "../fonts/nunito" !default;

/* nunito-300 - latin */
@font-face {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 300;
	src: url("#{$NunitoFontPath}/nunito-v11-latin-300.eot"); /* IE9 Compat Modes */
	src: local("Nunito Light"), local("Nunito-Light"),
		url("#{$NunitoFontPath}/nunito-v11-latin-300.eot?#iefix") format("embedded-opentype") /* IE6-IE8 */,
		url("#{$NunitoFontPath}/nunito-v11-latin-300.woff2") format("woff2") /* Super Modern Browsers */,
		url("#{$NunitoFontPath}/nunito-v11-latin-300.woff") format("woff") /* Modern Browsers */,
		url("#{$NunitoFontPath}/nunito-v11-latin-300.ttf") format("truetype") /* Safari, Android, iOS */,
		url("#{$NunitoFontPath}/nunito-v11-latin-300.svg#Nunito") format("svg"); /* Legacy iOS */
}
/* nunito-regular - latin */
@font-face {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	src: url("#{$NunitoFontPath}/nunito-v11-latin-regular.eot"); /* IE9 Compat Modes */
	src: local("Nunito Regular"), local("Nunito-Regular"),
		url("#{$NunitoFontPath}/nunito-v11-latin-regular.eot?#iefix") format("embedded-opentype") /* IE6-IE8 */,
		url("#{$NunitoFontPath}/nunito-v11-latin-regular.woff2") format("woff2") /* Super Modern Browsers */,
		url("#{$NunitoFontPath}/nunito-v11-latin-regular.woff") format("woff") /* Modern Browsers */,
		url("#{$NunitoFontPath}/nunito-v11-latin-regular.ttf") format("truetype") /* Safari, Android, iOS */,
		url("#{$NunitoFontPath}/nunito-v11-latin-regular.svg#Nunito") format("svg"); /* Legacy iOS */
}
/* nunito-600 - latin */
@font-face {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	src: url("#{$NunitoFontPath}/nunito-v11-latin-600.eot"); /* IE9 Compat Modes */
	src: local("Nunito SemiBold"), local("Nunito-SemiBold"),
		url("#{$NunitoFontPath}/nunito-v11-latin-600.eot?#iefix") format("embedded-opentype") /* IE6-IE8 */,
		url("#{$NunitoFontPath}/nunito-v11-latin-600.woff2") format("woff2") /* Super Modern Browsers */,
		url("#{$NunitoFontPath}/nunito-v11-latin-600.woff") format("woff") /* Modern Browsers */,
		url("#{$NunitoFontPath}/nunito-v11-latin-600.ttf") format("truetype") /* Safari, Android, iOS */,
		url("#{$NunitoFontPath}/nunito-v11-latin-600.svg#Nunito") format("svg"); /* Legacy iOS */
}
/* nunito-700 - latin */
@font-face {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	src: url("#{$NunitoFontPath}/nunito-v11-latin-700.eot"); /* IE9 Compat Modes */
	src: local("Nunito Bold"), local("Nunito-Bold"),
		url("#{$NunitoFontPath}/nunito-v11-latin-700.eot?#iefix") format("embedded-opentype") /* IE6-IE8 */,
		url("#{$NunitoFontPath}/nunito-v11-latin-700.woff2") format("woff2") /* Super Modern Browsers */,
		url("#{$NunitoFontPath}/nunito-v11-latin-700.woff") format("woff") /* Modern Browsers */,
		url("#{$NunitoFontPath}/nunito-v11-latin-700.ttf") format("truetype") /* Safari, Android, iOS */,
		url("#{$NunitoFontPath}/nunito-v11-latin-700.svg#Nunito") format("svg"); /* Legacy iOS */
}
/* nunito-800 - latin */
@font-face {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 800;
	src: url("#{$NunitoFontPath}/nunito-v11-latin-800.eot"); /* IE9 Compat Modes */
	src: local("Nunito ExtraBold"), local("Nunito-ExtraBold"),
		url("#{$NunitoFontPath}/nunito-v11-latin-800.eot?#iefix") format("embedded-opentype") /* IE6-IE8 */,
		url("#{$NunitoFontPath}/nunito-v11-latin-800.woff2") format("woff2") /* Super Modern Browsers */,
		url("#{$NunitoFontPath}/nunito-v11-latin-800.woff") format("woff") /* Modern Browsers */,
		url("#{$NunitoFontPath}/nunito-v11-latin-800.ttf") format("truetype") /* Safari, Android, iOS */,
		url("#{$NunitoFontPath}/nunito-v11-latin-800.svg#Nunito") format("svg"); /* Legacy iOS */
}
