.select-wrapper {
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	select {
		@extend %form-field-styling;
		appearance: none;
		background-color: $white;
		overflow: hidden;
		padding-right: 24px;
		text-overflow: ellipsis;
		vertical-align: middle;
		white-space: nowrap;
		option {
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		&::-ms-expand {
			display: none;
		}
	}
	&:after {
		@include opacity(50);
		@include icon("\f0d7");
		height: auto;
		font-size: 18px;
		pointer-events: none;
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translate(0, -50%);
		width: auto;
		z-index: 9;
	}
	.ie9 &,
	.ie8 &,
	.ie7 &,
	.lt-ie7 & {
		&:before {
			background: $white;
			content: "";
			height: 35px;
			pointer-events: none;
			position: absolute;
			right: 3px;
			top: 2px;
			z-index: 7;
			width: 45px;
		}
	}
}
