@import "../main";

.page-title {
	font-size: 2rem;
	font-weight: 800;
	margin-bottom: 0.5rem;
}

.section-header {
	font-size: 1.4rem;
	font-weight: 800;
	margin-bottom: 0;
}

.instruction {
	margin-top: 0.5rem;
}

.section {
	&.instructions > p {
		margin-top: 0.5rem;
	}
}

.cover-section {
	margin: 0 0 1rem;

	.cover-type-header {
		font-size: 1.1rem;
		font-weight: 600;
		margin: 0 0 1rem;
	}

	.cover-card {
		$shadow: rgba($black, 0.25%);

		// sass-lint:disable no-duplicate-properties
		@include small-tablet {
			width: 48%;
			width: calc(50% - 1rem);
		}

		@include tablet {
			width: 31%;
			width: calc(33.33% - 1rem);
		}
		// sass-lint:enable no-duplicate-properties

		background-color: $gray83;
		border-radius: 10px;
		box-shadow: 0 4px 4px $shadow;
		display: flex;
		flex-direction: column;
		flex-grow: 0;
		flex-shrink: 1;
		margin: 1rem 0.5rem;
		overflow: hidden;
		transition: background-color 0.3s linear;
		width: 100%;

		.no-flexbox & {
			display: table-cell;
		}

		&.active {
			background-color: darken($aliceblue, 5%);
		}

		.section {
			display: block;
			flex-grow: 1;
			flex-shrink: 1;
			font-size: 0.8rem;
			padding: 0.5rem;

			&.image {
				flex-grow: 0;
				flex-shrink: 0;
			}

			&.description {
				flex-grow: 3;
			}
		}

		img {
			display: block;
			height: auto;
			margin: auto;
			max-width: 100%;
			width: auto;
		}

		.heading {
			font-size: 1.1em;
			font-weight: 700;
			margin: 0;
		}

		.sub-heading {
			margin: 0 0 1rem;
		}

		.details {
			flex-grow: 1;

			.radio-options {
				$borderline: rgba($black, 0.2%);
				border-top: 1px solid $borderline;
				margin: 1rem 0;
				padding-top: 1rem;
			}
		}

		.sample-includes {
			$bgline: rgba($black, 0.1%);
			$bgshade: rgba($black, 0.08%);
			background-color: $bgshade;
			border-top: 1px solid $bgline;
			font-style: italic;

			.title {
				margin: 0 0 0.5rem;
			}
		}

		ul {
			margin-left: 1rem;
			padding-left: 0.5rem;
		}
	}
}
