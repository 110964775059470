*,
*:after,
*:before {
	box-sizing: border-box;
	line-height: normal;
	margin: 0;
	padding: 0;
	font-family: "Nunito", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

p {
	margin: 1em 0;
}

a {
	color: currentColor;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
	&[href^="mailto:"] {
		color: $dodgerblue;
	}
}
