%secondary {
	$AccentColor: darken($silver, 7%);
	background-color: $silver;
	border-color: $AccentColor;
	margin-right: 2rem;
	text-shadow: 1px 1px 2px $AccentColor;
	&:hover,
	&:focus {
		background-color: lighten($silver, 5%);
	}
}
%secondary-outlined {
	$AccentColor: $gray60;
	background-color: $offwhite;
	border-color: $AccentColor;
	&:hover,
	&:focus {
		background-color: $white;
		border-color: darken($gray60, 10%);
	}
}
%disabled {
	$AccentColor: $lightgray;
	background-color: lighten($lightgray, 5%) !important;
	border-color: $AccentColor !important;
	cursor: default !important;
	text-shadow: 1px 1px 2px $AccentColor !important;
	&:hover,
	&:focus {
		background-color: lighten($lightgray, 5%) !important;
	}
}
