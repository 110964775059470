// local variable defaults
$FieldHeight: 40px !default;
$FieldFontSize: 1rem !default;
$FieldBorderRadius: 4px !default;
$FieldMarginTop: 6px !default;

%form-field-styling {
	box-sizing: border-box;
	border-radius: $FieldBorderRadius;
	border-width: 2px;
	border-style: solid;
	border-color: $lightgray;
	height: $FieldHeight;
	font-size: $FieldFontSize;
	outline: none;
	padding: 0.4rem 0.6rem;
	transition: 0.25s all linear;
	width: 100%;
	&:hover {
		border-color: darken($gainsboro, 35%);
	}
	&:focus,
	&:active {
		border-color: $dodgerblue;
	}
}

%disabled-field {
	border: 1px solid $gray83;
	background-color: $gray93;
	&:active,
	&:hover,
	&:focus {
		outline: none;
		background-color: $gray93;
	}
}
