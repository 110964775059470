.form-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	width: 100%;
	.no-flexbox & {
		display: table;
		table-layout: fixed;
	}
	&.column {
		flex-direction: column;
	}
	&.btn-row {
		margin: 1rem 0;
	}
	&.align-center {
		justify-content: center;
		text-align: center;
	}
	&.align-left {
		justify-content: flex-start;
		text-align: left;
	}
	&.align-right {
		justify-content: flex-end;
		text-align: right;
	}
	&.buttons {
		margin: 1.5rem 0;
	}
	.form-item,
	.field {
		display: block;
		flex-grow: 1;
		flex-shrink: 1;
		padding: 0.5rem;
		.no-flexbox & {
			border-spacing: 10px 0;
			display: table-cell;
		}
		&.required {
			> label {
				&:before {
					@include icon("\f069");
					color: $firebrick2;
					font-size: 10px;
					margin-right: 2px;
					vertical-align: middle;
				}
				span {
					vertical-align: middle;
				}
			}
		}
		.hint {
			color: $gray40;
			font-size: 0.8rem;
			margin-left: 8px;
		}
	}
}
