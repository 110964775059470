// Mixins

@mixin col-width($size) {
	display: block;
	.no-flexbox & {
		display: table-cell;
	}

	@if is-number($size) and $size == 12 {
		flex-basis: 100%;
		min-width: 100%;
	} @else {
		flex-basis: 0%;
		min-width: auto;
		width: calc((100 / 12) * #{$size} * 1%);
		// flex-basis: calc(100% / 12);
		// flex-basis: calc((100 / 12) * #{$size} * 1%);
		// min-width: calc((100 / 12) * #{$size} * 1%);
	}
}

@mixin opacity($number) {
	opacity: $number/100;
	filter: alpha(opacity=($number));
}

@mixin icon($icon) {
	content: $icon;
	font-family: "FontAwesome";
}

@mixin ieFlexFix() {
	min-width: 1px;
	margin-top: auto;
}

/**
 * Determine if something is the root or a child
 *
 * @see https://stackoverflow.com/questions/17181849/placeholder-mixin-scss-css#answer-17181946
 */
@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

/**
 * Set placeholder styling
 *
 * @see https://stackoverflow.com/questions/17181849/placeholder-mixin-scss-css#answer-17181946
 */
@mixin placeholder {
	@include optional-at-root("::-webkit-input-placeholder") {
		@content;
	}

	@include optional-at-root(":-moz-placeholder") {
		@content;
	}

	@include optional-at-root("::-moz-placeholder") {
		@content;
	}

	@include optional-at-root(":-ms-input-placeholder") {
		@content;
	}
}

$mobile-sm-width: 320px !default;
$mobile-rg-width: 480px !default;
$tablet-sm-width: 600px !default;
$tablet-rg-width: 768px !default;
$desktop-sm-width: 960px !default;
$desktop-rg-width: 1200px !default;
$desktop-lg-width: 1470px !default;
$large-screen-width: 2000px !default;

@mixin mobile {
	@media only screen and (min-width: #{$mobile-rg-width}) {
		@content;
	}
}

@mixin tablet {
	@media only screen and (min-width: #{$tablet-rg-width}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-rg-width}) {
		@content;
	}
}

@mixin small-mobile {
	@media only screen and (min-width: #{$mobile-sm-width}) {
		@content;
	}
}

@mixin small-tablet {
	@media only screen and (min-width: #{$tablet-sm-width}) {
		@content;
	}
}

@mixin small-desktop {
	@media (min-width: #{$desktop-sm-width}) {
		@content;
	}
}

@mixin large-desktop {
	@media (min-width: #{$desktop-lg-width}) {
		@content;
	}
}

@mixin large-screens {
	@media (min-width: #{$large-screen-width}) {
		@content;
	}
}

@mixin ie11 {
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		@content;
	}
}
