// common colors
$white: #ffffff !default;
$blue: #0000ff !default;
$red: #ff0000 !default;
$black: #000000 !default;
$green: #008000 !default;
$orange: #ff8000 !default;
$gold: #ffd700 !default;
$yellow: #ffff00 !default;

// grays
$offwhite: #f9f9f9 !default;
$gainsboro: #dcdcdc !default;
$lightgray: #d3d3d3 !default;
$silver: #c0c0c0 !default;
$sgilightgray: #aaaaaa !default;
$sgidarkgray: #555555 !default;
$gray: #808080 !default;
$lightslategray: #778899 !default;
$slategray: #708090 !default;
$darkslategray: #2f4f4f !default;
$white-smoke: #f5f5f5 !default;
$pdf-viewer-gray: #404040 !default;
$gray90: #e5e5e5 !default;
$gray93: #ededed !default;
$gray83: #d4d4d4 !default;
$gray80: #cccccc !default;
$gray73: #bababa !default;
$gray60: #999999 !default;
$gray47: #787878 !default;
$gray40: #666666 !default;
$gray29: #4a4a4a !default;
$gray23: #3b3b3b !default;
$gray13: #212121 !default;

// blues
$aliceblue: #f0f8ff !default;
$dodgerblue: #1e90ff !default;
$steelblue: #4682b4 !default;
$royalblue: #4169e1 !default;
$royalblue1: #4876ff !default;
$royalblue2: #436eee !default;
$royalblue3: #3a5fcd !default;
$royalblue4: #27408b !default;
$cobalt: #3d59ab !default;
$navy: #000080 !default;
$bluelagoon: #005f84 !default;

// greens
$honeydew: #f0fff0 !default;
$mint: #bdfcc9 !default;
$darkseagreen: #8fbc8f !default;
$mountainmeadow: #19ad84 !default;
$forestgreen: #228b22 !default;
$darkgreen: #006400 !default;
$b101green: #6fb427 !default;
$darkb101green: #55a047 !default;
$frontend_approvegreen: #25cea2 !default;

// oranges
$tan: #d2b48c !default;
$wheat: #f5deb3 !default;
$wheat1: #ffe7ba !default;
$wheat2: #eed8ae !default;
$wheat3: #cdba96 !default;
$wheat4: #8b7e66 !default;
$carrot: #ed9121 !default;
$orange1: #ffa500 !default;
$orange2: #ee9a00 !default;
$orange3: #cd8500 !default;
$orange4: #8b5a00 !default;
$darkorange: #ff8c00 !default;
$darkorange1: #ff7f00 !default;
$darkorange2: #ee7600 !default;
$darkorange3: #cd6600 !default;
$darkorange4: #8b4500 !default;
$lighttangelo: #eb7035 !default;

// reds
$lightcoral: #f08080 !default;
$cinnabar: #e8284c !default;
$rosybrown1: #ffc1c1 !default;
$rosybrown2: #eeb4b4 !default;
$rosybrown3: #cd9b9b !default;
$rosybrown4: #8b6969 !default;
$firebrick: #b22222 !default;
$firebrick1: #ff3030 !default;
$firebrick2: #ee2c2c !default;
$firebrick3: #cd2626 !default;
$firebrick4: #8b1a1a !default;
$maroon: #800000 !default;
$frontend_rejectionred: #ff0067 !default;

// yellows
$goldenrod: #daa520 !default;
$goldenrod1: #ffc125 !default;
$goldenrod2: #eeb422 !default;
$goldenrod3: #cd9b1d !default;
$goldenrod4: #8b6914 !default;
$darkgoldenrod: #b8860b !default;
$darkgoldenrod1: #ffb90f !default;
$darkgoldenrod2: #eead0e !default;
$darkgoldenrod3: #cd950c !default;
$darkgoldenrod4: #8b6508 !default;
