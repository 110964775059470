.button,
button {
	border-radius: 0.4rem;
	border-style: solid;
	border-width: 1px;
	cursor: pointer;
	font-size: 0.9rem;
	font-weight: 600;
	height: auto;
	line-height: normal;
	margin: 0 0.5em;
	min-height: 20px;
	min-width: 80px;
	outline: none;
	padding: 5px 8px;
	text-align: center;
	text-decoration: none;
	transition: 0.4s ease-in-out background, 0.4s ease-in-out border;
	&.primary,
	&[type="submit"] {
		$AccentColor: darken($orange1, 5%);
		background-color: $orange1;
		border-color: $AccentColor;
		color: $white;
		text-shadow: 1px 1px 2px $AccentColor;
		&.lrg {
			border-width: 2px;
			font-size: 1.5rem;
			font-weight: 800;
			padding: 0.5em 1em;
		}
		&:hover,
		&:focus {
			background-color: lighten($orange1, 8%);
		}
	}
	&.secondary {
		@extend %secondary;
	}
	&.disabled,
	&[disabled] {
		@extend %disabled;
	}
	&.outline {
		@extend %secondary-outlined;
	}
}
